<template>
  <div
    v-if="promoData.promo"
    class="cart-entry-promo-upsell flex--row-start-center text--clr-black mt-6"
  >
    <div class="cart-entry-promo-upsell__promo-info-icon" v-if="getIcon">
      <bleach-image
        class="cart-entry-promo-upsell__promo-info-icon-image"
        :src="getIcon.original_secure_url"
        :default-size="[50, 'ar_1']"
        imageQuality="60"
      />
    </div>
    <div
      class="cart-entry-promo-upsell__promo-info-text text-body-small text--left"
    >
      <span class="text--w-bold text--clr-purple_dark">{{
        promoData.promo.title
      }}</span
      >&nbsp;<span>{{ getPromoMessage }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  props: {
    promoData: Object
  },

  data: () => ({}),

  computed: {
    ...mapState("ui", ["productPageOverlay"]),
    ...mapGetters("cart", ["getLineItems"]),

    getIcon() {
      return this.promoData.promo && this.promoData.promo.icon[0]
    },

    getPromoMessage() {
      const tagList = [
        {
          tag: "SHORTFALL",
          value: this.getShortfall
        },
        {
          tag: "QUANTITY_IN_CART",
          value: this.getQualifyingQuantityInCart
        }
      ]

      const _hasShortfall =
        this.getQualifyingQuantityInCart == 0 || this.getShortfall > 0

      let _msg = _hasShortfall
        ? this.promoData.promo.message
        : this.promoData.promo.messageQualified

      if (_msg && tagList) {
        tagList.forEach(entry => {
          _msg = _msg.replace(`[**${entry.tag}**]`, entry.value)
        })
      }
      return _msg || ""
    },

    getQualifyingQuantityInCart() {
      // Get the qualifying lineItems in the cart
      return this.promoData.getQualifyingQuantityInCart
    },

    getShortfall() {
      return this.promoData.promo.threshold - this.getQualifyingQuantityInCart
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-entry-promo-upsell {
  background-color: rgba(getcolour(bleach_purple), 0.1);
  // background: linear-gradient(
  //   90deg,
  //   rgba(getcolour(bleach_pink), 0.2) 0%,
  //   rgba(getcolour(bleach_purple), 0.2) 100%
  // );

  padding: 6px 10px;

  &__promo-info {
    // margin-bottom: 30px;
  }

  &__promo-info-link {
    outline: none;
    border: none;
    background: none;
  }

  &__promo-info-icon {
    flex: 0 0 20px;
    width: 20px;
    min-height: 20px;
    margin-right: 0.5em;
  }

  &__promo-info-text {
    span {
      display: inline-block;
    }
  }

  &__overlay-control {
    position: relative;
    display: inline-block;
    margin-left: 0.5em;
    width: 0.65em;
    height: 0.65em;
    margin-top: 0.4em;
  }

  &__overlay-control-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease;
    transform: rotate(45deg);
    transform-origin: center;
    // z-index: -1;

    path {
      stroke: getcolour(bleach_black);
      vector-effect: non-scaling-stroke;
      stroke-width: 1.5px;
    }

    &--white {
      path {
        stroke: getcolour(bleach_white);
      }
    }

    &--active {
      transform: rotate(0deg);
    }
  }
}
</style>
